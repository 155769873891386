









































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import i18n from '@/i18n';
import { SampleVariable, SampleClass } from '@/store/modules/sample/types';
import Unwatcher from '@/utils/unwatcher';
import { SetTargetPopulationParams } from '@/store/modules/sample';
import Log from '@/utils/log';
import { Flavor } from '@/flavor/types';
import { flavor } from '@/flavor';

/** Extend SampleClass + SampleVariable for use in table */
interface TableClass extends SampleClass {
  percentageString : string; // formatted percetage
  weightedSampleString : string; // formatted weightedSample
  targetPopulationEdit : string;
}

interface TableVariable extends SampleVariable {
  isTargetPopulationOk : boolean; // Ture if target population shares sum to 100%
  targetPopulationColorType : string;
  classes: Array<TableClass>;
}

/** Transform weightedSample from server to formatted weighted sample string */
function getWeightedSampleString(weightedSample : number|null, isShare : boolean) : string {
  if (weightedSample !== null) {
    let s = Math.round(weightedSample * (isShare ? 100 : 1)).toString();
    s += ' %';
    return s;
  }
  return '';
}

@Component({
  props: {
    type: String, // 'sample' or 'weighting'
  },
  components: {
  },
})
export default class SampleTableComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  public tableData: Array<TableVariable> = [];
  public weightOnRows: Array<TableVariable> = [];
  public defaultOpenedDetailed: Array<number|null> = [];
  public isLoadingTableData : boolean = false; // is loading table data?
  public isLoadingWeightedSample : boolean = false; // is loading weighed sample?
  public loadError : boolean = false;
  isInTargetPopulationOnBlur : boolean = false;
  flavor: Flavor = flavor;
  activeViewer = Flavor.activeViewer;

  public async created() {
    const self = this;

    // When filter state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => [getters['sample/isLoaded'], getters['sample/isLoadError']],
      (newValue: any|null, oldValue: any|null) => {
        if (newValue !== oldValue) {
          Log.log('Update table data');
          const r = self.getTableData();
          self.tableData = r.tableData;
          self.isLoadingTableData = r.isLoadingTableData;
          self.defaultOpenedDetailed = r.defaultOpenedDetailed;
          self.weightOnRows = r.weightOnRows;
          self.loadError = newValue[1];
        }
      },
    ));
  }

  public beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  /**
   * Loads table data and is used by both data() and store watch
   */
  private getTableData() {
    const dateFormatter = new Intl.DateTimeFormat(i18n.locale);

    const data = this.$store.state.sample.variables;

    // Make copy of store .variables
    // and convert targetPopulation [0, 1] to perecentage and put in
    // targetPopulationEdit.
    let tableRows : Array<TableVariable> = data.map((row : SampleVariable) => {
      const ret : any = { ...row };
      ret.targetPopulationColorType = 'is-primary'; // overriden in weighting table by updateTargetPopulationOk
      ret.classes = ret.classes.map((cls : TableClass) => {
        const c : TableClass = { ...cls };
        if (c.percentage !== null) {
          c.percentageString = Math.round(c.percentage * 100).toString();
          if (ret.isShare) c.percentageString += ' %';
        } else {
          c.percentageString = '';
        }
        if (c.targetPopulation !== null) {
          c.targetPopulationEdit = (c.targetPopulation * (ret.isShare ? 100 : 1)).toString();
        } else {
          c.targetPopulationEdit = '';
        }
        c.weightedSampleString = getWeightedSampleString(c.weightedSample, ret.isShare);
        return c;
      });
      return ret;
    });

    // If weighting => remove rows that we cannot weight on
    if (this.$props.type === 'weighting') {
      tableRows = tableRows.filter((row : TableVariable) => row.canWeightOn);
    }

    // Which rows should be expanded by default?
    const defaultOpenedDetailed = tableRows.filter(
      (row : TableVariable) => row.classes.length > 0 && row.isExpanded,
    ).map((row : TableVariable) => row.id);

    // Which rows to check by default?
    const weightOnRows = tableRows.filter((row : TableVariable) => row.weightOn);

    // Get .isTargetPopulationOk
    this.updateTargetPopulationOk(tableRows);
    this.updateTargetPopulationTypeColor(tableRows, weightOnRows);

    // IF YOU ADD anything new here ALSO add to watch method above.
    return {
      tableData: tableRows,
      isLoadingTableData: !this.$store.getters['sample/isLoaded'] && !this.$store.getters['sample/isLoadError'],
      defaultOpenedDetailed,
      weightOnRows,
      loadError: this.$store.getters['sample/isLoadError'],
    };
  }

  data() {
    return this.getTableData();
  }

  onReload() {
    try {
      this.$store.dispatch('sample/load');
    } catch {
      // Do nothing
    }
  }

  /* eslint no-param-reassign: ["error", { "props": false }] */
  /**
   * Updates .isTargetPopulationOk on each variable in variables parameter
   * by reading state from store.
   * Does not modify any state data on this.
   * @return True if component state changed.
   */
  updateTargetPopulationOk(variables : Array<TableVariable>) : boolean {
    let update = false;
    variables.forEach((variable : TableVariable) => {
      const ok = this.$store.getters['sample/isTargetPopulationSum1'](variable.id);
      if (ok !== variable.isTargetPopulationOk) {
        variable.isTargetPopulationOk = ok;
        update = true;
      }
    });
    return update;
  }

  updateTargetPopulationTypeColor(
    variables : Array<TableVariable>, weightOnRows : Array<TableVariable>,
  ) : boolean {
    let update = false;
    for (let i = 0; i < variables.length; i += 1) {
      const variable = variables[i];
      const weightOn = this.$props.type === 'weighting' && weightOnRows.find(
        (v : TableVariable) => v.id === variable.id,
      ) !== undefined;
      const color = !weightOn || variable.isTargetPopulationOk ? 'is-primary' : 'is-assertive';
      if (color !== variable.targetPopulationColorType) {
        variable.targetPopulationColorType = color;
        update = true;
      }
    }
    return update;
  }

  /* eslint no-continue: ["off", {}] */
  /**
   * Checks if data to weight is valid and then
   * requests weighted sample data from server.
   * @pre updateTargetPopulationOk
   */
  async updateWeightedSample(
    variables : Array<TableVariable>, weightOnRows : Array<TableVariable>,
  ) : Promise<void> {
    Log.log('Check if input is ok for weighting');
    if (this.$props.type !== 'weighting') return;

    let ok = true;
    const weightOnMap : any = {};
    for (let i = 0; i < variables.length; i += 1) {
      const variable = variables[i];
      if (variable.id === null) continue;
      const weightOn = weightOnRows.indexOf(variable) !== -1;
      weightOnMap[variable.id!] = weightOn;
      ok = ok && (!weightOn || variable.isTargetPopulationOk);
      if (!ok) break;
    }

    if (ok) {
      Log.log('Request new weighted sample');
      try {
        this.isLoadingWeightedSample = true;
        await this.$store.dispatch('sample/load');
      } catch (e) {
        // Do nothing
      } finally {
        this.isLoadingWeightedSample = false;
      }
    }

    Log.log('Assign weighted sample');
    for (let i = 0; i < variables.length; i += 1) {
      const storeVar = this.$store.state.sample.variables.find(
        (v : SampleVariable) => v.id === variables[i].id,
      );
      const weightOn = weightOnRows.indexOf(variables[i]) !== -1;
      for (let c = 0; c < variables[i].classes.length; c += 1) {
        const tableClass = variables[i].classes[c];
        const storeClass = storeVar === undefined ? undefined
          : storeVar.classes.find((sc : SampleClass) => sc.id === tableClass.id);
        tableClass.weightedSample = storeClass !== undefined ? storeClass.weightedSample : null;
        tableClass.weightedSampleString = getWeightedSampleString(
          tableClass.weightedSample,
          variables[i].isShare,
        );
      }
    }
  }

  onDetailsOpen(row : SampleVariable) {
    row.isExpanded = true;
    this.$store.commit('sample/setIsExpanded', { variableId: row.id, isExpanded: true });
  }

  onDetailsClose(row : SampleVariable) {
    row.isExpanded = false;
    this.$store.commit('sample/setIsExpanded', { variableId: row.id, isExpanded: false });
  }

  onMoreInfo(row : any) {
    // TODO: open more info right panel
    this.$buefy.snackbar.open('Feature not yet available');
  }

  /* eslint class-methods-use-this: ["off", {}}] */
  onTargetPopulationChange(variable : TableVariable, cls : TableClass, $event : Event) {
    // No-oop for now. Later on possible locally validate data on type
  }

  async onTargetPopulationBlur(variable : TableVariable, cls : TableClass, $event : Event) {
    $event.stopPropagation();
    if (this.isInTargetPopulationOnBlur || variable.id === null) {
      return;
    }
    Log.log('onTargetPopulationBlur', $event);
    this.isInTargetPopulationOnBlur = true;
    try {
      const factor = variable.isShare ? 100.0 : 1.0;
      const edit = cls.targetPopulationEdit;
      const multipleValues = edit.trim().split(' ');
      const editClassIndex = variable.classes.findIndex((c : SampleClass) => c.id === cls.id);
      const params : SetTargetPopulationParams[] = [];
      for (let i = 0; i < multipleValues.length; i += 1) {
        const valueClassIndex = editClassIndex + i;
        if (valueClassIndex < 0) {
          continue; // Should not happen
        } else if (valueClassIndex >= variable.classes.length) {
          break;
        }
        const valueClass = variable.classes[valueClassIndex];
        if (valueClass.id === null) continue;

        let value = parseFloat(multipleValues[i].replace(',', '.')) / factor;
        if (!Number.isNaN(value)) {
          value = Math.max(0, value); // Change negative values to 0
        }

        // Write back how the value is intepreted
        valueClass.targetPopulationEdit = Number.isNaN(value) ? '' : (value * factor).toString();

        // Save to store
        if (i === 0) this.$store.commit('query/clear');
        params.push({
          variableId: variable.id,
          classId: valueClass.id,
          targetPopulation: Number.isNaN(value) ? null : value,
        });
      }
      try {
        await this.$store.dispatch('sample/setTargetPopulation', params);
      } catch (e) {
        await this.$buefy.dialog.alert({
          message: 'Unable to save target population',
          type: 'is-assertive',
          container: 'main',
        } as any); // any: container field is missing in buefy
      }

      // Update which variables can be weighted on?
      if (this.$props.type === 'weighting') {
        let update = this.updateTargetPopulationOk(this.tableData);
        update = this.updateTargetPopulationTypeColor(this.tableData, this.weightOnRows) || update;
        if (update) {
          this.$forceUpdate();
        }
        this.updateWeightedSample(this.tableData, this.weightOnRows);
      }
    } finally {
      this.isInTargetPopulationOnBlur = false;
    }
  }

  /** On toggle a weight on checkbox for one row */
  async onRowCheck(list : Array<TableVariable>, row : TableVariable) {
    const enabled = list.indexOf(row) !== -1;
    this.$store.commit('query/clear');
    try {
      await this.$store.dispatch('sample/setWeightOn', {
        variableId: row.id,
        weightOn: enabled,
      });
    } catch (e) {
      await this.$buefy.dialog.alert({
        message: 'Unable to save "weight on" status',
        type: 'is-assertive',
        container: 'main',
      } as any);
    }
    // Update weightOn on tableData
    row.weightOn = enabled;
    // Update border color of targetPopulation fields
    const update = this.updateTargetPopulationTypeColor(this.tableData, list);
    if (update) {
      this.$forceUpdate();
    }
    this.updateWeightedSample(this.tableData, list);
  }

  onBack() {
    this.$router.push('/survey');
  }

  onNext() {
    this.$router.push('/weighting');
  }
}

